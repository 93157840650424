<template>
  <GDialog v-model="emailSent" max-width="500">
    <div class="g-dialog wrapper">
      <div class="content">
        <div v-if="emailSent" class="alert alert-success" role="alert">
          Obrigado, retornaremos o mais breve possível.
        </div>
      </div>

      <div class="actions">
        <button class="btn btn-outline-gray" @click="closeDialog()">
          Fechar
        </button>
      </div>
    </div>
  </GDialog>

  <div class="row main">
    <loading :active="loading" :is-full-page="true"></loading>
    <div class="col-xl-9 organization">
      <div class="box bg-white">
        <div class="logos">
          <img src="../images/mais-logo.svg" alt="Mapa do Impacto Social" />
        </div>
        <h2>Fale Conosco</h2>
        <form @submit.prevent="sendEmail()" @change="validateForm()" class="form">
          <div class="row">
            <div class="col-xl-6 col-lg-12 mb-3">
              <label class="form-label required">Nome</label>
              <input v-model="form.nome.value" type="text" class="form-control" :class="form.nome.status"
                placeholder="Nome" required />
              <div class="invalid-feedback">
                {{ form.nome.message }}
              </div>
            </div>

            <div class="col-xl-6 col-lg-12 mb-3">
              <label class="form-label required">E-mail</label>
              <input v-model="form.email.value" type="email" class="form-control" :class="form.email.status"
                placeholder="E-mail" required />
              <div class="invalid-feedback">
                {{ form.email.message }}
              </div>
            </div>

            <div class="col-md-12 mb-3">
              <label class="form-label required"> Como podemos te ajudar? </label>
              <Select2 v-model="form.suggestions.value" @select="validateForm()" :class="{
                valid: form.suggestions.value.length > 0,
                invalid: form.suggestions.value.length == 0,
              }" :options="suggestions_options" :settings="settings">
              </Select2>
              <span v-if="form.suggestions.value.length === 0" class="text-error">
                {{ form.suggestions.message }}
              </span>
            </div>
          </div>
          <div class="mt-3 mb-3" style="text-align: right">
            <button :disabled="!canSendEmail" type="submit" class="btn-save">
              Enviar E-mail
            </button>
          </div>

          <div id="print" v-show="false">
            <h3 style="
                    font-size: calc(1.3rem + 0.6vw);
                    margin-top: 0;
                    margin-bottom: 0.5rem;
                    font-weight: 500;
                    line-height: 1.2;
                  ">
              Olá,
            </h3>
            <p style="margin-top: 0; margin-bottom: 1rem">
              <b>{{ form.nome.value }}</b>&nbsp;
              <a :href="'mailto:' + form.email.value">&lt;{{ form.email.value }}&gt;</a>
              entrou em contato tendo o(s) interesse(s):
            </p>

            <h3 style="margin: 1rem 0">Como podemos te ajudar?</h3>
            <ol style="margin-top: 0; margin-bottom: 1rem; padding-left: 2rem">
              <li v-for="motivo in form.suggestions.value" :key="motivo">
                {{ motivo }}
              </li>
            </ol>
          </div>

          <div id="print-2" v-show="false">
            <img :src="getImageBaseUrl('mais-fale-conosco.jpg')" />
          </div>

          <div id="faq-assunto-1" v-show="false">
            <h2>
              Olá, <b>{{ form.nome.value }}</b>
            </h2>
            <p>
              Agradecemos seu contato.
              Enviamos algumas dicas e um material explicativo que pode te ajudar. =)
            </p>
            <p>
              Caso o problema persista, por favor, entre em contato com o suporte: <a href="mailto:support@seallintelligence.com">Seall Intelligence</a>
            </p>
            <br />
            <h3>Equipe MAIS</h3>

          </div>

          <div id="faq-assunto-2" v-show="false">
            <h2>
              Olá, <b>{{ form.nome.value }}</b>
            </h2>
            <p>
              Agradecemos seu interesse em apoiar o MAIS.
              Pedimos a gentileza de enviar um e-mail para <a
                href="mailto:mapadoimpacto.social@gmail.com.">mapadoimpacto.social@gmail.com</a>.
            </p>
            <br />
            <h3>Equipe MAIS</h3>
          </div>

          <div id="faq-assunto-3" v-show="false">
            <h2>
              Olá, <b>{{ form.nome.value }}</b>
            </h2>
            <p>
              Agradecemos o seu contato. Você selecionou "preciso de ajuda para outros assuntos", para isso pedimos a
              gentileza de enviar um e-mail para <a
                href="mailto:mapadoimpacto.social@gmail.com.">mapadoimpacto.social@gmail.com</a> com as seguintes
              informações:
            </p>
            <p>
              <strong>Título:</strong>
              Assunto a ser tratado + Nome da Organização
            </p>
            <p>
              <strong>Corpo:</strong>
              Descreva suas necessidades com o máximo de detalhes para que nossa equipe possa melhor te auxiliar
            </p>
            <p>
              <strong>Prints:</strong>
              Em caso de dúvidas com relação a plataforma pode enviar prints das telas referente as suas dúvidas que
              encaminharemos com o time de suporte técnico.
            </p>
            <br />
            <h3>Equipe MAIS</h3>
          </div>
        </form>
      </div>
    </div>
    <div class="col-xl-3 image"></div>
  </div>

  <img class="w-100" src="./../images/barra_ods.svg" alt="Barra" />
</template>
<script>
import $ from "jquery";
import { cloneDeep } from "lodash";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Select2 from "vue3-select2-component";
import SeallBasicLambdasService from "../../services/seall-basic-lambdas/seall-basic-lambdas.service";
import Validators from "../../utils/Validators";
import PoweredBy from "./PoweredBy.vue";
export default {
  name: "MaisFaleConosco",
  components: {
    Loading,
    Select2,
    PoweredBy,
  },
  data() {
    return {
      loading: false,
      emailSent: false,
      canSendEmail: false,
      form: {
        nome: {
          value: null,
          status: "",
          message: "",
        },
        email: {
          value: null,
          status: "",
          message: "",
        },
        suggestions: {
          value: [],
          status: "",
          message: "",
        },
      },
      settings: {
        width: "resolve",
        language: "pt-BR",
        multiple: true,
        templateResult: (state) => {
          let text = state;
          if (state.id) text = state.text;
          return $(`<div class="text-truncate" title="${text}">${text}</div>`);
        },
        templateSelection: (state) => {
          let text = state;
          if (state.id) text = state.text;
          return $(`<div class="text-truncate" title="${text}">${text}</div>`);
        },
      },
      options: [
        "Quero cadastrar a OSC em que eu participo.",
        "Quero receber atualizações de dados da plataforma e convites da rede de parceiros",
        "Quero receber análises sobre os dados da plataforma",
        "Quero me voluntariar para contactar outras OSCs para cadastro na plataforma",
        "Quero conhecer OSCs para investir recursos ",
      ],
      suggestions_options: [
        "Estou com dificuldade de realizar o cadastro da OSC",
        "Quero apoiar o MAIS",
        "Preciso de ajuda para outros assuntos",
      ],
      openDialog: false,
    };
  },
  mounted() {
    this.$i18n.locale = "pt";
  },
  methods: {
    validateForm() {
      const name = Validators.validateName(this.form.nome.value);
      this.form.nome.status = Validators.isValidOrInvalid(name);
      this.form.nome.message = Validators.setMessageError(
        name,
        "Nome Inválido"
      );

      const email = Validators.validateEmail(this.form.email.value);
      this.form.email.status = Validators.isValidOrInvalid(email);
      this.form.email.message = Validators.setMessageError(
        email,
        "E-mail Inválido"
      );

      const suggestions = cloneDeep(this.form.suggestions);

      this.form.suggestions.status = Validators.isValidOrInvalid(
        suggestions.value.length > 0
      );
      this.form.suggestions.message = Validators.setMessageError(
        suggestions,
        "Selecione uma opção"
      );

      this.canSendEmail =
        name &&
        email &&
        suggestions.value.length > 0;
    },
    getImageBaseUrl(url) {
      return process.env.VUE_APP_FRONTEND_S3_ASSETS + "email/" + url;
    },
    sendEmail() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("print").innerHTML;

      const html = `<!DOCTYPE html>
                        <html>
                          <head>
                              <style>
                                h2 {
                                  font-style: normal;
                                  font-weight: bold;
                                  font-size: 24px;
                                  line-height: 120%;
                                  color: rgb(73, 46, 133);
                                  margin: 0vh 0vw;
                                  padding: 2vh 0vw;
                                }
                                p {
                                  font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
                                  font-size: 16px;
                                  font-weight: 400;
                                  line-height: 24px;
                                  color: #212529;
                                }
                              </style>                              
                            </head>
                            <body>
                                ${prtHtml}
                            </body>
                        </html>`;

      const hasSuggestOne = this.form.suggestions.value.includes(
        this.suggestions_options[0]
      );
      const hasSuggestTwo = this.form.suggestions.value.includes(
        this.suggestions_options[1]
      );
      const hasSuggestThree = this.form.suggestions.value.includes(
        this.suggestions_options[2]
      );


      const emailTo = hasSuggestTwo ? "mapadoimpacto.social@gmail.com" : "support@seallintelligence.com";

      const emails = {
        emailTo: emailTo,
        htmlBody: html,
        subject: "Contato via MAIS - Cadastro OSC",
      };

      const payload = { emails };

      this.loading = true;
      this.emailSent = false;
      SeallBasicLambdasService.sendEmail(payload)
        .then(() => {
          this.loading = false;
          this.sendConfirmationEmail();
        })
        .catch(() => {
          this.loading = false;
          this.$notify({
            title: this.$t("common.error_http"),
            type: "error",
          });
        });
    },
    sendConfirmationEmail() {
      this.loading = true;

      const hasSuggestOne = this.form.suggestions.value.includes(
        this.suggestions_options[0]
      );
      const hasSuggestTwo = this.form.suggestions.value.includes(
        this.suggestions_options[1]
      );
      const hasSuggestThree = this.form.suggestions.value.includes(
        this.suggestions_options[2]
      );

      let htmlId = "print-2";
      let attachments = [];

      switch (true) {
        case hasSuggestOne:
          htmlId = "faq-assunto-1";
          attachments.push(
            {
              bucket: process.env.VUE_APP_S3_BUCKET,
              filename: "faq.pdf",
              key: "mais/faq.pdf",
            },
          );
          break;
        case hasSuggestTwo:
          htmlId = "faq-assunto-2";
          break;
        case hasSuggestThree:
          htmlId = "faq-assunto-3"
          attachments.push(
            {
              bucket: process.env.VUE_APP_S3_BUCKET,
              filename: "faq.pdf",
              key: "mais/faq.pdf",
            },
          );
          break;
        default:
          break;
      }

      // Get HTML to print from element
      const prtHtml = document.getElementById(htmlId).innerHTML;

      const html = `<!DOCTYPE html>
                        <html>
                            <head>
                              <style>
                                h2 {
                                  font-style: normal;
                                  font-weight: bold;
                                  font-size: 24px;
                                  line-height: 120%;
                                  color: rgb(73, 46, 133);
                                  margin: 0vh 0vw;
                                  padding: 2vh 0vw;
                                }
                                p {
                                  font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
                                  font-size: 16px;
                                  font-weight: 400;
                                  line-height: 24px;
                                  color: #212529;
                                }
                              </style>                              
                            </head>
                            <body>
                                ${prtHtml}
                            </body>
                        </html>`;

      const emails = {
        emailTo: this.form.email.value,
        htmlBody: html,
        subject: "Confirmação de Contato - MAIS",
        attachments,
      };

      const payload = { emails };

      SeallBasicLambdasService.sendEmail(payload)
        .then(() => {
          this.emailSent = true;
          this.clearForm();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$notify({
            title: this.$t("common.error_http"),
            type: "error",
          });
        });
    },
    closeDialog() {
      this.emailSent = false;
    },
    clearForm() {
      this.form = {
        nome: {
          value: null,
          status: "",
          message: "",
        },
        email: {
          value: null,
          status: "",
          message: "",
        },
        suggestions: {
          value: [],
          status: "",
          message: "",
        },
      };
    }
  },
};
</script>
<style lang="scss">
@import "../css/bootstrap/bootstrap.min.css";

@font-face {
  font-family: Nunito-Regular;
  src: url(../fonts/Nunito/Nunito-Regular.ttf);
}

@font-face {
  font-family: NunitoSans-Regular;
  src: url(../fonts/Nunito_Sans/NunitoSans-Regular.ttf);
}

@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px lightgray;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #492e85;
  border-radius: 5px;
}

.g-dialog.wrapper {
  color: #000;

  .content {
    padding: 20px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
}

html,
body {
  border: 0vh 0vw;
  height: 100%;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  width: 100%;
  background-color: #533a8b;
  overflow: hidden;
}

.organization {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 25%;
  background-image: url("./../images/background-create.png");
  background-repeat: repeat;
  background-size: cover;
}

.main {
  background-color: #533a8b;
  height: 98vh;
  max-height: 98vh;
}

.box {
  box-shadow: 0px 3px 5px -1px rgba(112, 119, 128, 0.2),
    0px 6px 10px rgba(112, 119, 128, 0.14),
    0px 1px 18px rgba(112, 119, 128, 0.12);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-items: center;
  min-height: 500px;
  width: 80%;

  h2 {
    font-family: Poppins-Regular;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    color: #492e85;
    margin: 0vh 0vw;
    padding: 2vh 0vw;
  }

  .logos {
    margin-top: 2rem;
    text-align: center;

    img {
      width: 90%;
      padding: 1rem;
    }
  }

  .form {
    min-height: 50vh;
    max-height: 50vh;
    padding: 2rem 5rem;
    width: 100%;
    overflow-y: auto;

    .form-label {
      font-family: "Roboto", sans-serif;

      &.required:after {
        content: " *";
        color: #00b9bd;
      }
    }

    .btn-save {
      background-color: #533a8b;
      border-radius: 4px;
      color: #ffffff;
      font-family: NunitoSans-Regular;
      border-style: hidden;
      padding: 0.7rem;
      text-transform: uppercase;
    }
  }
}

@media (max-width: 1163px) {
  .image {
    display: none;
  }

  .box {
    .form {
      padding: 1rem 2rem;
    }
  }
}
</style>